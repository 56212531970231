import { ChevronDown } from 'lucide-react';
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { clearSessionStorage } from 'untils/auth';

const sessionClear = [
  'from',
  'to',
  'flyLocationTo',
  'flyLocationForm',
  'userFormData',
  'emptyLegsStartDate',
  'emptyLegsEndDate',
];

export default class Navbar extends Component {
  //clear data
  initPage = (e, url) => {
    e.preventDefault();
    sessionClear.forEach((element) => {
      clearSessionStorage(element);
    });
    this.props.history.push(`/${url}`);
  };

  render() {
    const { isScroll } = this.props;
    return (
      <ul className={`header-nav ${this.props.name}`}>
        <li className="header-nav-parent">
          <span>
            <Link to="/">Airacer</Link>
            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <a href="/jetSale">Jet Sales</a>
            </li>
          </ul>
        </li>
        <li className="header-nav-parent">
          <span>
            <a
              href="javascripts:;"
              onClick={(e) => {
                this.initPage(e, '');
              }}
            >
              Search Flights
            </a>
            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <NavLink
                to="/charterQuote"
                onClick={(e) => {
                  this.initPage(e, 'charterQuote');
                }}
              >
                Private Charters
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/emptyLeg"
                onClick={(e) => {
                  this.initPage(e, 'emptyLeg');
                }}
              >
                Empty Leg Flights
              </NavLink>
            </li>
            {/* <li>
                <NavLink
              to="/searchFlights"
              onClick={(e) => {
                this.initPage(e, 'searchFlights');
              }}
            >
              Scheduled Flights
            </NavLink>
            </li> */}
            {/* <li>
            <NavLink
              to="/petFriendlyFlight"
              onClick={(e) => {
                this.initPage(e, 'petFriendlyFlight');
              }}
            >
              Pet Friendly Flight
            </NavLink>
            </li> */}
          </ul>
        </li>
        <li>
          <NavLink
            to="/membership"
            onClick={(e) => {
              this.initPage(e, 'membership');
            }}
          >
            Memberships
          </NavLink>
        </li>
        <li>
          <NavLink to="/Blog">Blog</NavLink>
        </li>
        <li className="header-nav-parent">
          <span>
            {/* About Airacer */}
            <NavLink to="/aboutUs">About Us</NavLink>

            <ChevronDown size={20} color={isScroll ? 'black' : 'white'} />
          </span>
          <ul className="header-nav-children about-airacer">
            <li>
              <NavLink to="/contactUs">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/partnerWithUs">Partner With Us</NavLink>
            </li>
            <li>
              <NavLink to="/affiliatePartner">Become an Affiliate Partner</NavLink>
            </li>
          </ul>
        </li>
      </ul>
    );
  }
}
